import { NavLink } from "react-router-dom";
import '../../stylesheets/Box.scss';

export function LoadingBox(props) {
  return (
    <div className="box loading-box">
      <div className="box-numb">
        <p>
          4
        </p>
      </div>
      <div className="box-text">
        <p>
          Documents Required
        </p>
      </div>
    </div>
  );
}

export function Box(props) {
  var link = props?.link;
  if (!props?.link) {
    link = "shipments";
  }
  return (
    <NavLink to={link} className="box">
      <div className="box-numb">
        <p>
          {props?.number}
        </p>
      </div>
      <div className="box-text">
        <p>
          {props?.text}
        </p>
      </div>
    </NavLink>
  );
}