import { useState } from 'react';
import { Warehouse } from './Warehouse';

export const RenderWarehouses = (params) => {
  const {
    warehouses,
    userId,
    sendWarehouses
  } = params;

  const handleNewWarehouse = (e) => {
    e.preventDefault();

    const undef = {
      address: "",
      city: "New York",
      state: "",
      emails: [{
        email: "",
        name: ""
      }],
      id: Math.floor(Math.random() * 1000),
      name: "",
      phone: "",
      user_id: userId,
      zip: "10001",
      isNew: true
    };
    let aux = [...warehouses];
    aux.push(undef)
    sendWarehouses(aux);
  }

  const handleUpdateWarehouse = (obj) => {
    let aux = [...warehouses];
    let warehousesIds = [];
  
    warehouses.forEach(t => {
      warehousesIds.push(t?.id);
    });
  
    let isId = warehousesIds.includes(obj?.id); // determine if warehouse exists
    if (isId) {
      aux.forEach(t => {
        if (t?.id == obj?.id) {
          t.name = obj?.name;
          t.phone = obj?.phone;
          t.address = obj?.address;
          t.state = obj?.state;
          t.city = obj?.city;
          t.zip = obj?.zip;
          t.emails = obj?.emails; // Correct key
        }
      });
      sendWarehouses(aux);
    } else {
      aux.push(obj);
      sendWarehouses(aux);
    }
  };
  

  const handleDeleteWarehouse = (i) => {
    let aux = [...warehouses]
    aux.splice(i, 1)
    sendWarehouses(aux)
  }

  const [emailError, setEmailError] = useState(false);

  const handleEmailError = (error) => {
    setEmailError(error)
  }

  return (
    <div className="multiform warehouses">
      <div className="tab-header">
        <h3 className="section-title personal">
          Warehouses
        </h3>
        <button
          className="new-trucker"
          onClick={handleNewWarehouse}
        >
          <p>New Warehouse</p>
        </button>
      </div>
      <div className="divisor"></div>
      <div className="all warehouses">
        {warehouses?.map((warehouse, i) => {
          return (
            <div key={i}>
              <div className="individual warehouse">
                <Warehouse
                  warehouses={warehouses}
                  isNew={warehouse?.isNew}
                  warehouseInfo={warehouse}
                  index={i}
                  updateWarehouse={handleUpdateWarehouse}
                  deleteWarehouse={handleDeleteWarehouse}
                  emailError={handleEmailError}
                />
              </div>
              {emailError && (
                <p className='error-message'>
                  Please check the provided emails
                </p>
              )}
              <div className="divisor"></div>
            </div>
          )
        })}

      </div>
    </div>
  );
};