import { Outlet } from "react-router-dom";
import  Sidebar  from './Sidebar';

const Layout = () => {
	return (
		<>
      <div id="main-container">
        <Sidebar />
        <div className="wrapper">
	      	<Outlet />
	      </div>
      </div>
		</>
	);
};

export default Layout;