import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { Title, LoadingTitle } from "../../components/Title.jsx";
import "../../stylesheets/Billing.scss";
import * as FaIcons from "react-icons/fa";
import { BillingTable } from "../../components/table/billing/BillingTable";
import { toast } from "react-toastify";

export const Billing = () => {
  const cookies = new Cookies();
  const name = cookies.get("name_user");
  const token = cookies.get("token_user");
  const navigate = useNavigate();

  if (!token) {
    navigate("/login");
  }

  const URL = process.env.REACT_APP_API_URL + "/user";

  const [invoices, setInvoices] = useState();

  const getInvoices = async () => {
    try {
      const response = await fetch(`${URL}/invoices`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      let data = await response.json();
      if (data.result) {
        setInvoices(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <Title
        title="Billing"
        links={[
          { link: "/", title: "Home" },
          { link: "/billing", title: "Billing" },
        ]}
      />
      <div id="billing" className="page-content">
        <h3>Payment Methods</h3>
        <div className="payment-boxes">
          <div className="payment-box" id="box-1">
            <p className="payment-header">ACH/Wire</p>
            <div className="payment-body">
              <p className="box-2_first-body">
                Account: 472011571 <br />
                Routing: 021000021
              </p>
              <div className="box-2_second-body">
                <p>
                  Swift Code: CHASUS33 <br />
                  Bank: Chase
                </p>
              </div>
            </div>
          </div>
          <div className="payment-box" id="box-2">
            <p className="payment-header">Quickpay/Zelle</p>
            <div className="payment-body">
              <p>
                username is <br />
                hillel@atlanticfb.com
              </p>
            </div>
          </div>
          <div className="payment-box" id="box-3">
            <p className="payment-header">Email Check Image</p>
            <div className="payment-body">
              <p id="right">
                Send to:
                <br />
                <a href="mailto:hillel@atlanticfb.com" className="email">
                  hillel@atlanticfb.com
                </a>
                <br />
                <a href="mailto:mimi@atlanticfb.com" className="email">
                  mimi@atlanticfb.com
                </a>
                <br />
                <a href="mailto:abraham@atlanticfb.com" className="email">
                  abraham@atlanticfb.com
                </a>
              </p>
              <a
                className="email"
                href="mailto:abraham@atlanticfb.com; mailto:mimi@atlanticfb.com; mailto:abraham@atlanticfb.com"
              >
                Send to all
              </a>
            </div>
          </div>
          <div className="payment-box" id="box-4">
            <p className="payment-header">Mail Check</p>
            <div className="payment-body">
              <p>
                Atlantic Freight Brokers <br />
                453 Broadway STE 111. Brooklyn NY 11211
              </p>
            </div>
          </div>
        </div>
        <div className="table">
          {invoices && <BillingTable invoices={invoices} />}
        </div>
      </div>
    </>
  );
};

export const BillingInvoice = () => {
  const { id } = useParams();

  const cookies = new Cookies();
  const name = cookies.get("name_user");
  const token = cookies.get("token_user");
  const navigate = useNavigate();

  if (!token) {
    navigate("/login");
  }

  const URL = process.env.REACT_APP_API_URL + "/user";

  const [invoice, setInvoice] = useState();

  const getInvoices = async () => {
    try {
      const response = await fetch(`${URL}/invoices/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      let data = await response.json();
      if (data?.invoice) {
        setInvoice(data?.invoice);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUrl = (path) => {
    let formattedURL = `${process.env.REACT_APP_BASE_URL}/${path}`;
    return `${formattedURL}`;
  };

  const getFileName = (path) => {
    return path.split("/")[path.split("/").length - 1];
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      {invoice ? (
        <Title title={`Invoice ${invoice?.shipment?.file_number}`} />
      ) : (
        <LoadingTitle />
      )}
      <div id="invoice" className="page-content">
        <div className="left-side">
          {invoice && (
            <embed
              type="application/pdf"
              src={getUrl(invoice?.path)}
              width="100%"
              height="100%"
            />
          )}
        </div>
        <div className="right-side">
          <section id="section-2" className="pay-info">
            <p className="title">ACH/Wire</p>
            <div className="pay-links">
              <p id="right">
                Account: 472011571 <br />
                Routing: 021000021
              </p>
              <p id="left">
                Swift Code: CHASUS33 <br />
                Bank: Chase
              </p>
            </div>
          </section>
          <section id="section-3" className="pay-info">
            <p className="title">Quickpay/Zelle</p>
            <div className="pay-links">
              <p id="right">
                Recipient: <span className="email">hillel@atlanticfb.com</span>
              </p>
            </div>
          </section>
          <section id="section-4" className="pay-info">
            <p className="title">Email Check Image</p>
            <div className="pay-links">
              <p id="right">
                Send to:
                <br />
                <a href="mailto:hillel@atlanticfb.com" className="email">
                  hillel@atlanticfb.com
                </a>
                <br />
                <a href="mailto:mimi@atlanticfb.com" className="email">
                  mimi@atlanticfb.com
                </a>
                <br />
                <a href="mailto:abraham@atlanticfb.com" className="email">
                  abraham@atlanticfb.com
                </a>
              </p>
              <a
                className="email"
                href="mailto:abraham@atlanticfb.com; mailto:mimi@atlanticfb.com; mailto:abraham@atlanticfb.com"
              >
                Send to all
              </a>
            </div>
          </section>
          <section id="section-5" className="pay-info">
            <p className="title">Mail Check</p>
            <div className="pay-links">
              <p id="right">
                Atlantic Freight Brokers <br />
                453 Broadway STE 111. Brooklyn NY 11211
              </p>
            </div>
          </section>
          {invoice && invoice.backup.length > 0 && (
            <a
              className="name"
              href={`${process.env.REACT_APP_BASE_URL}/${invoice.backup}`}
              download="backup"
              target="_blank"
            >
              <div
                className={`file-visualizer flex jc-c ai-c column available`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="40"
                  viewBox="0 0 30 40"
                >
                  <path
                    id="file-arrow-down-solid"
                    d="M30,10H20V0ZM20,12.5H30V36.25A3.75,3.75,0,0,1,26.25,40H3.75A3.75,3.75,0,0,1,0,36.25V3.75A3.75,3.75,0,0,1,3.75,0H17.5V10A2.5,2.5,0,0,0,20,12.5Zm-.078,10.547L16.875,26.1V18.125a1.875,1.875,0,0,0-3.75,0V26.1l-3.117-3.055A1.6,1.6,0,0,0,8.75,22.5a1.855,1.855,0,0,0-1.326.547,1.875,1.875,0,0,0,0,2.652l6.25,6.25a1.875,1.875,0,0,0,2.652,0l6.25-6.25a1.876,1.876,0,1,0-2.654-2.652Z"
                    fill="#23b574"
                  />
                </svg>

                {getFileName(invoice?.backup)}
              </div>
            </a>
          )}
        </div>
      </div>
    </>
  );
};
