import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import "./index.css";
import "./App.scss";

//Pages import

import Home from "./pages/Home/Home";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Shipments from "./pages/Shipments/Shipments.jsx";
import { ShipmentInfo } from "./pages/Shipments/ShipmentInfo";
import { Billing, BillingInvoice } from "./pages/Billing/Billing.jsx";
import Reports from "./pages/Reports/Reports.jsx";
import Settings from "./pages/Settings/Settings.jsx";
import { Error } from "./components/Error";
import Layout from "./components/Layout";
import { Login } from "./components/login/Login";
import { ForgotPassword } from "./components/login/ForgotPassword";
import { ResetPassword } from "./components/login/ResetPassword";
import { Users } from "./pages/Users/Users";
import { NewUser } from "./pages/Users/NewUser";
import { ErrorBoundaryComponent } from "./components/ErrorBoundaryComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

// Test comment

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route ErrorBoundary={ErrorBoundaryComponent}>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/shipments">
          <Route index element={<Shipments />} />
          <Route path="/shipments/:id" element={<ShipmentInfo />}></Route>
        </Route>
        <Route path="/billing">
          <Route index element={<Billing />} />
          <Route path="/billing/:id" element={<BillingInvoice />} />
        </Route>
        <Route path="/users">
          <Route index element={<Users />} />
          <Route path="/users/:id" element={<NewUser />} />
        </Route>
        <Route path="/reports" element={<ComingSoon />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Error />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer />
  </React.StrictMode>
);
