import '../stylesheets/Title.scss'
import mainLogo from '../assets/atlantic-main-logo.png';
import { NavLink } from 'react-router-dom';

export const LoadingTitle = (props) => {
    return (
        <div className="page-title loading">
            <div className="cont">
                <img src={mainLogo} alt="main-logo" id='responsive-logo' />
                <h3>
                    Loading...
                </h3>
            </div>
            <div id="grey-line"></div>
        </div>
    );
}

export const Title = (props) => {
    return (
        <div className="page-title">
            <div className="cont">

                <img src={mainLogo} alt="main-logo" id='responsive-logo' />
                <h3>
                    {props.title}
                </h3>
          {props.links && <p className="breadcrumbs">{props?.links.map((link, count) => <span key={count}>{count > 0 ? <ArrowIcon /> : null} <NavLink to={link.link}>{link.title}</NavLink></span>)}</p>}
            </div>
            <div id="grey-line"></div>
        </div>
    );
}

export const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.317" height="10.317" viewBox="0 0 7.317 10.317">
      <g transform="translate(-344.092 198.408) rotate(-90)">
        <line x2="3.75" y2="4.5" transform="translate(189.5 345.5)" fill="none" stroke="#303030" strokeLinecap="round" strokeWidth="2"/>
        <line x1="3.75" y2="4.5" transform="translate(193.25 345.5)" fill="none" stroke="#303030" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  );
}