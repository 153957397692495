import { useState, useEffect } from "react";
import { States } from "../Settings/States";
import Select from 'react-select';
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import {
    FaAngleUp,
    FaAngleDown,
    FaWarehouse,
} from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import "../../stylesheets/Warehouse.scss";

export const DeliveryOrderWarehouse = (params) => {
    const {
        index,
        warehouseInfo,
        updateWarehouse,
        deleteWarehouse,
        isNew,
        emailError,
        closeModal,
        addWarehouse, // Function to add the new warehouse to the parent state
        setSelectedWarehouse // Function to set the selected warehouse in the parent component
    } = params;

    const [opened, setOpened] = useState(isNew);
    const [newName, setNewName] = useState(warehouseInfo?.name || "");
    const [displayName, setDisplayName] = useState(warehouseInfo?.name || "New Warehouse");
    const [newContact, setNewContact] = useState(warehouseInfo?.emails?.[0]?.name || "");
    const [newPhone, setNewPhone] = useState(warehouseInfo?.phone || "");
    const [newAddress, setNewAddress] = useState(warehouseInfo?.address || "");
    const [newCity, setNewCity] = useState(warehouseInfo?.city || "");
    const [newZip, setNewZip] = useState(warehouseInfo?.zip || "");
    const [state, setState] = useState(warehouseInfo?.state || "");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [existingWarehouses, setExistingWarehouses] = useState([]);
    const [fetching, setFetching] = useState(true);

    const URL = process.env.REACT_APP_API_URL + "/user/settings";
    const cookies = new Cookies();
    const token = cookies.get("token_user");

    const openTab = () => {
        setOpened(!opened);
    };

    const handleUpdate = (field, value) => {
        const updatedWarehouse = { ...warehouseInfo, [field]: value };
        updateWarehouse(index, updatedWarehouse);
    };

    const fetchUserSettings = async () => {
        setFetching(true);
        try {
            const response = await fetch(`${URL}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = await response.json();

            if (response.status === 200) {
                setExistingWarehouses(data.user[0]?.warehouses || []);
            } else {
                setError("Failed to fetch user settings.");
            }
        } catch (e) {
            setError("An error occurred while fetching user settings.");
            console.error(e);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const createWarehouse = async () => {
        setLoading(true);

        const warehouse = {
            name: newName,
            phone: newPhone,
            address: newAddress,
            city: newCity,
            state: state,
            zip: newZip,
            emails: [{ name: newContact, email: "" }]
        };

        const settingsObj = {
            warehouses: [warehouse, ...existingWarehouses]
        };

        try {
            const response = await fetch(`${URL}/save`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(settingsObj),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success("Warehouse created successfully!");
                addWarehouse(result.warehouses[result.warehouses.length - 1]);
                setDisplayName(newName);
                if (closeModal) closeModal();
                // Fetch shipment data again
                if (typeof window.getShipmentData === "function") {
                    window.getShipmentData();
                }
            } else {
                toast.error("Failed to create warehouse");
            }
        } catch (e) {
            setError("Something went wrong. Please, try again in a few minutes!");
            toast.error("Something went wrong. Please, try again in a few minutes!");
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="individual-header" onClick={openTab} style={{ pointerEvents: fetching ? 'none' : 'auto', opacity: fetching ? 0.5 : 1 }}>
                <h3 className="name">
                    {displayName}
                </h3>
                <div className="arrow">
                    {opened ? <FaAngleUp /> : <FaAngleDown />}
                </div>
            </div>
            {opened && (
                <div className="warehouse-form" style={{ padding: "20px", maxWidth: "600px", backgroundColor: "#f4f4f4", borderRadius: "5px", pointerEvents: fetching ? 'none' : 'auto', opacity: fetching ? 0.5 : 1 }}>
                    {fetching ? (
                        <div style={{ textAlign: 'center' }}>Loading...</div>
                    ) : (
                        <>
                            <div className="input-section name-phone" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div className="input-container">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Warehouse Name</label>
                                    <div className="input" style={{ position: "relative" }}>
                                        <FaWarehouse style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="input-section name-phone" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div className="input-container">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Contact Name</label>
                                    <div className="input" style={{ position: "relative" }}>
                                        <FaWarehouse style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input type="text" value={newContact} onChange={(e) => setNewContact(e.target.value)} style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                    </div>
                                </div>
                                <div className="input-container phone">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Phone Number</label>
                                    <div className="input" style={{ position: "relative" }}>
                                        <BsTelephoneFill style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input type="text" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="input-section address" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div className="input-container street">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Street Address</label>
                                    <div className="input" style={{ position: "relative" }}>
                                        <MdLocationOn style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input type="text" value={newAddress} onChange={(e) => setNewAddress(e.target.value)} style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                    </div>
                                </div>
                                <div className="input-section address-details" style={{ display: "flex", gap: "10px" }}>
                                    <div className="input-container city" style={{ flex: 1 }}>
                                        <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>City</label>
                                        <div className="input">
                                            <input type="text" value={newCity} onChange={(e) => setNewCity(e.target.value)} style={{ paddingLeft: "10px", flex: "1", padding: "10px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                        </div>
                                    </div>
                                    <div className="input-container state" style={{ flex: 1.5 }}>
                                        <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>State</label>
                                        <Select
                                            options={States.map(state => ({ value: state?.abbreviation, label: state?.abbreviation }))}
                                            onChange={(e) => setState(e.value)}
                                            defaultValue={{ value: state, label: state }}
                                            className="select"
                                            styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                                        />
                                    </div>
                                    <div className="input-container zip" style={{ flex: 1 }}>
                                        <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>ZIP</label>
                                        <div className="input">
                                            <input type="text" value={newZip} onChange={(e) => setNewZip(e.target.value)} style={{ paddingLeft: "10px", flex: "1", padding: "10px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                                <button className="create-warehouse" onClick={createWarehouse} disabled={loading} style={{ backgroundColor: "var(--green)", width: "46%", border: "none", color: "white", borderRadius: "5px", padding: "5px 10px", height: "fit-content" }}>
                                    {loading ? "Creating..." : "Create Warehouse"}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DeliveryOrderWarehouse;
