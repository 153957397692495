import { useState } from "react";
import { States } from "./States";
import Select from 'react-select';

import {
  FaAngleUp,
  FaAngleDown,
  FaWarehouse,
} from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

import "../../stylesheets/Warehouse.scss";

export const Warehouse = (params) => {
  const {
    index,
    warehouseInfo,
    updateWarehouse,
    deleteWarehouse,
    isNew,
    emailError
  } = params;

  const [opened, setOpened] = useState(isNew);
  const [newName, setNewName] = useState(warehouseInfo?.name);
  const [newContact, setNewContact] = useState(warehouseInfo?.emails?.[0]?.name || "");
  const [newPhone, setNewPhone] = useState(warehouseInfo?.phone);
  const [newAdress, setNewAdress] = useState(warehouseInfo?.address);
  const [newCity, setNewCity] = useState(warehouseInfo?.city);
  const [newZip, setNewZip] = useState(warehouseInfo?.zip);
  const [state, setState] = useState(warehouseInfo?.state);

  const openTab = () => {
    if (opened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteWarehouse(index)
  };

  const handleNameUpdate = (e) => {
    e.preventDefault();
    var curWarehouse = warehouseInfo;
    curWarehouse['name'] = e?.target?.value;
    updateWarehouse(curWarehouse);
  }

  const handlePhoneUpdate = (e) => {
    e.preventDefault();
    var curWarehouse = warehouseInfo;
    curWarehouse['phone'] = e?.target?.value;
    updateWarehouse(curWarehouse);
  }

  const handleAdressUpdate = (e) => {
    e.preventDefault();

    var curWarehouse = warehouseInfo;
    curWarehouse['address'] = e?.target?.value;
    updateWarehouse(curWarehouse);
  }

  const handleCityUpdate = (e) => {
    e.preventDefault();

    var curWarehouse = warehouseInfo;
    curWarehouse['city'] = e?.target?.value;
    updateWarehouse(curWarehouse);
  }

  const handleZipUpdate = (e) => {
    e.preventDefault();

    var curWarehouse = warehouseInfo;
    curWarehouse['zip'] = e?.target?.value;
    updateWarehouse(curWarehouse);
  }

  const handleContactUpdate = (e) => {
    e.preventDefault();
    setNewContact(e.target.value);
    var curWarehouse = { ...warehouseInfo };
    curWarehouse.emails = [{ ...curWarehouse.emails[0], name: e.target.value }];
    updateWarehouse(curWarehouse);
  }

  // const addContactInput = (e) => {
  //   e.preventDefault();
  //   let aux = warehouseInfo;
  //   aux.emails.push({
  //     warehouse_id: warehouseInfo?.id,
  //     name: "",
  //     email: "",
  //   });
  //   updateWarehouse(aux);
  // }

  // const handleUpdateContact = (e, i, type) => {
  //   e.preventDefault();
  //   let aux = warehouseInfo;
  //   aux.emails[i].email = e.target.value;
  //   updateWarehouse(aux);
  // }

  // const handleDeleteEmail = (i) => {
  //   let aux = warehouseInfo;
  //   aux.emails.splice(i, 1);
  //   updateWarehouse(aux);
  // }

  const updateState = (value) => {
    var curWarehouse = warehouseInfo;
    curWarehouse['state'] = value;
    updateWarehouse(curWarehouse);
  }

  return (
    <>
      <div className="individual-header" onClick={openTab}>
        <h3 className="name">
          {warehouseInfo?.name ? warehouseInfo?.name : "New Warehouse"}
        </h3>
        <div className="arrow">
          {opened ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {opened ? (
        <div className="warehouse-form">
          <div className="input-section name-phone">
            <div className="input-container">
              <label className="input-label">Warehouse Name</label>
              <div className="input">
                <div className="input-icon"><FaWarehouse /></div>
                <input type="text" value={warehouseInfo?.name}
                  onChange={handleNameUpdate} />
              </div>
            </div>
          </div>
          <div className="input-section name-phone">
            <div className="input-container">
              <label className="input-label">Contact Name</label>
              <div className="input">
                <div className="input-icon"><FaWarehouse /></div>
                <input type="text" value={newContact} onChange={handleContactUpdate} />
              </div>
            </div>
            <div className="input-container phone">
              <label className="input-label">Phone Number</label>
              <div className="input">
                <div className="input-icon"><BsTelephoneFill /></div>
                <input type="text" value={warehouseInfo?.phone}
                  onChange={handlePhoneUpdate} />
              </div>
            </div>
          </div>
          <div className="input-section adress">
            <div className="left">
              <div className="input-container street">
                <label className="input-label">Street Address</label>
                <div className="input">
                  <div className="input-icon"><MdLocationOn /></div>
                  <input type="text" value={warehouseInfo?.address}
                    onChange={handleAdressUpdate} />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="input-container city">
                <label className="input-label">City</label>
                <div className="input">
                  <input type="text" value={warehouseInfo?.city}
                    onChange={handleCityUpdate} />
                </div>
              </div>

              <div className="input-container state">
                <label className="input-label">State</label>
                <Select
                  options={States.map(state => ({ value: state?.abbreviation, label: state?.abbreviation }))}
                  onChange={(e) => updateState(e.value)}
                  defaultValue={{ value: warehouseInfo?.state, label: warehouseInfo?.state }}
                  className="select"
                />
              </div>

              <div className="input-container zip">
                <label className="input-label">ZIP</label>
                <div className="input">
                  <input type="text" value={warehouseInfo?.zip}
                    onChange={handleZipUpdate} />
                </div>
              </div>
            </div>
          </div>

          <button
            className="delete-warehouse"
            onClick={handleDelete}
          >
            <p>Delete { }</p>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
