import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Select from "react-select";

import Sidebar from '../../components/Sidebar';
import { Title } from '../../components/Title';
import { URL, token, customSelectStyles, LoadingTable, UnsavedChanges } from '../../components/Utils';
import { DeleteUser } from './DeleteUser';

import '../../stylesheets/Admins.scss';
import '../../stylesheets/Users.scss';

export const Users = () => {
  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()

  const [finalUrl, setFinalUrl] = useState(`${URL}/user/subusers`);
  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    filter: "",
    order_by: "",
    field: "",
  });

  const gotoPreviousPage = () => {
    let aux = { ...extraUrl }
    if (page > 1) {
      setPage(page - 1)
      aux.page = page - 1;
      setExtraUrl(aux)
    }
  }

  const gotoNextPage = () => {
    let aux = { ...extraUrl }
    if (page < totalPages) {
      setPage(page + 1)
      aux.page = page + 1;
      setExtraUrl(aux)
    }
  }

  const [noRecords, setNoRecords] = useState(false)

  const handleClientFilter = (e) => {
    if(e.value != "0") {
      const filteredUsers = users.filter(item =>
        item.role == e.value
      );

      setSearchResults(filteredUsers);
    } else {
      setSearchResults(users);
    }
  }

  const [searchResults, setSearchResults] = useState([])
  useEffect(() => {
    users && setSearchResults(users);
  }, [users])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredUsers = users.filter(item =>
      item.name.toLowerCase().includes(value)
    );

    setSearchResults(filteredUsers);
  };

  const handleOrderBy = (ord) => {
    let aux = { ...extraUrl }
    let orders = ord.split(',');
    aux.order_by = orders[0];
    aux.field = orders[1];
    setExtraUrl(aux)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0) {
        searchParams.append(key, extraUrl[key]);
      }
    }

    let aux = `${URL}/user/subusers?${searchParams}`;
    setFinalUrl(decodeURIComponent(aux));
  }, [extraUrl]);

  const getUsers = async () => {
    setLoading(true)
    try {
      const resp = await fetch(finalUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      if (resp.status === 200) {
        if (!data.hasOwnProperty("subusers")) {
          setNoRecords(true)
        } else {
          setNoRecords(false)
          setUsers(data.subusers.filter(user => user.active))
          // setTotalUsers(data.subusers.total)
          // setTotalPages(data.result.last_page)
          setLoading(false)
        }
      } else {
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getUsers();
  }, [finalUrl]);

  return (
    <>

      <div className="top">
        <Title
          title={`Users`}
          links={[
            { link: '/', title: 'Home' },
            { link: '/users', title: 'Users' }
          ]}
        />
      </div>
      <div id="home" className="page-content">
        <div id="users">
          <div className="data-table">
            <div className="header flex" style={{ marginBottom: "12px" }}>
              <div className="search-input">
                <input
                  type="text"
                  defaultValue={""}
                  onChange={handleSearch}
                />
                <div className="icon">
                  <BsSearch />
                </div>
              </div>
              <div className="filters flex">
                <Select
                  styles={customSelectStyles}
                  defaultValue={"all_customers"}
                  onChange={handleClientFilter}
                  options={[
                    { label: "All User", value: "0" },
                    { label: "Primary", value: "3" },
                    { label: "User", value: "5" },
                    { label: "Managers", value: "4" },
                  ]}
                  className="custom-select"
                  placeholder={"All Users"}
                />
                <NavLink to="/users/new-user">
                  <button id='new-user-button'>
                    Add User
                  </button>
                </NavLink>
              </div>
            </div>
            {noRecords ?
              <>
                <h4>
                  {`No users found`}
                </h4>
              </>
              :
              <>
                <UsersTable
                  loading={loading}
                  users={searchResults}
                  orderBy={handleOrderBy}
                  sortData={[extraUrl.order_by, extraUrl.field]}
                  removeUser={() => getUsers()}
                />
              </>
            }
          </div>
        </div>
      </div>

    </>
  )
}

const UsersTable = ({ users, orderBy, sortData, removeUser, loading }) => {

  const handleRole = (role) => {

    const Circle = ({ color }) => {
      return (
        <span className="circle" style={{
          display: "block",
          width: "7.5px",
          height: "7.5px",
          borderRadius: "50%",
          backgroundColor: color,
        }}>

        </span>
      );
    }
    let formRole = "";
    let color = "red";
    switch (role) {
      case 3:
        formRole = "Primary";
        color = "red";
        break;
      case 5:
        formRole = "User";
        color = "var(--green)";
        break;
      case 4:
        formRole = "Manager";
        color = "#197FB7";
        break;
      default:
        formRole = "Not defined";
        color = "red";
        break;
    }
    return (
      <>
        <span>
          <Circle color={color} />
        </span>
        {formRole}
      </>
    );
  }

  return (
    <>
      {loading ? <LoadingTable /> : <div className="table">
        <div className="table-header">
          <div className="left">
            <div
              className="table-head username"
              onClick={() => {
                orderBy(
                  sortData.join(",") === "1,0" ? "0, 0" : "1,0"
                )
              }}
            >
              <p>
                Username
              </p>
              <span className="arrow">
                {sortData.join(",") === "1,0" ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            </div>
            <div
              className="table-head role"
              onClick={() => {
                orderBy(
                  sortData.join(",") === "1,1" ? "0,1" : "1,1"
                )
              }}
            >
              <p>
                Position
              </p>
              <span className="arrow">
                {sortData.join(",") === "1,1" ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            </div>
          </div>
          <div className="right">
            <div
              className="table-head user-actions"
            >
              <p>
                Actions
              </p>
            </div>
          </div>
        </div>
        {!loading && typeof users !== "undefined" ?
          users.map((user, i) => {
            return (
              <div className="table-body" key={user.id}>

                <div className="left">
                  {user.role == "3" ? <div className="table-bod username">
                      <p>
                        {user.name}
                      </p>
                    </div> : <NavLink to={`/users/${user.id}`}>
                    <div className="table-bod username">
                      <p>
                        {user.name}
                      </p>
                    </div>
                  </NavLink>}
                  <div className="table-bod role">
                    <p className='table-role'>
                      {handleRole(user.role)}
                    </p>
                  </div>
                </div>
                <div className="right">
                  <div className="table-bod user-actions">
                    <div className="actions">
                      {user.role != "3" && <NavLink to={`/users/${user.id}`}>
                        <BiEditAlt />
                      </NavLink>}

                      {user.role != "3" && <DeleteUser
                        id={user.id}
                        from={"table"}
                        removeUser={() => removeUser()}
                      />}
                    </div>
                  </div>
                </div>
              </div>
            )
          })

          :
          <></>
        }
      </div>}
    </>
  );
}
