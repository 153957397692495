import { Trucker } from "./Trucker";
import { useState } from "react";

export const RenderTruckers = (params) => {
  const { truckers, sendTruckers } = params;

  const handleNewTrucker = (e) => {
    e.preventDefault();
    const undef = {
      id: Math.floor(Math.random() * 1000),
      name: "",
      phone: "",
      trucker_emails: [{ email: "", cc_emails: [] }],
      isNew: true,
    };
    let aux = [...truckers];
    aux.push(undef);
    sendTruckers(aux);
  };

  const handleUpdateTrucker = (obj) => {
    let aux = [...truckers];
    let truckerIds = [];

    truckers.forEach((t) => {

      truckerIds.push(t.id);
    });

    let isId = truckerIds.includes(obj.id); //determine is trucker exists


    if (isId) {
      //Update function


      aux.forEach((t) => {
        if (t.id == obj.id) {
          t.name = obj.name;
          t.phone = obj.phone;
          t.trucker_emails = obj.trucker_emails;
        }
      });
      sendTruckers(aux);
    } else {
      aux.push(obj);
      sendTruckers(aux);
    }

    sendTruckers(aux);
  };

  const handleDeleteTrucker = (i) => {
    let aux = [...truckers];
    let newAux = aux.filter((trucker) => {
      return trucker.id != i;
    });
    sendTruckers(newAux);
  };

  return (
    <div className="multiform truckers">
      <div className="tab-header">
        <h3 className="section-title personal">Truckers</h3>
        <button className="new-trucker" onClick={handleNewTrucker}>
          <p>New Trucker</p>
        </button>
      </div>
      <div></div>
      <div className="divisor"></div>
      <div className="all truckers">
        {truckers?.map((trucker, i) => {
          return (
            <div key={i}>
              <div className="individual trucker">
                <Trucker
                  tid={trucker?.id}
                  truckerInfo={trucker}
                  truckers={truckers}
                  temails={trucker?.trucker_emails}
                  phone={trucker?.phone}
                  index={i}
                  name={trucker?.name}
                  id={trucker?.id}
                  updateTrucker={handleUpdateTrucker}
                  deleteTrucker={handleDeleteTrucker}
                  isNew={trucker?.isNew}
                />
              </div>
              <div className="divisor"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
