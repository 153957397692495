import { useState } from "react";
//&Icons
import { BsTelephoneFill } from "react-icons/bs";
import { FaAngleUp, FaAngleDown, FaUser, FaEnvelope } from "react-icons/fa";

export const Trucker = (params) => {
  const { tid, truckerInfo, index, updateTrucker, deleteTrucker, isNew } =
    params;
  const [opened, setOpened] = useState(isNew);

  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [emailError, setEmailError] = useState(false);

  const openTab = () => {
    if (opened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteTrucker(tid);
  };

  const [numberOfEmailsError, setNumberOfEmailsError] = useState(false);

  const addEmailInput = (e) => {
    console.log("hre");
    e.preventDefault();
    let curTrucker = truckerInfo;

    let aux;

    if (truckerInfo?.trucker_emails[0]?.cc_emails) {
      aux = [...truckerInfo?.trucker_emails[0]?.cc_emails];
    } else {
      let cc_emails = [
        {
          cc_emails: [],
        },
      ];
      Object.assign(truckerInfo?.trucker_emails, cc_emails);
      aux = [...truckerInfo?.trucker_emails[0]?.cc_emails];
    }
    if (aux.length < 10) {
      aux.push("");
      curTrucker["trucker_emails"][0]["cc_emails"] = aux;
      updateTrucker(curTrucker);
    } else {
      setNumberOfEmailsError(true);
    }
  };

  const handleUpdateEmail = (i, value) => {
    console.log({ cur: truckerInfo });
    if (i === -1) {
      let curTrucker = truckerInfo;

      if (truckerInfo?.trucker_emails[0]?.email) {
        let aux = truckerInfo?.trucker_emails[0]?.email;

        aux = value;
        curTrucker["trucker_emails"][0]["email"] = aux;
        updateTrucker(curTrucker);
      } else {
        let email = [
          {
            email: value,
            cc_emails: truckerInfo?.trucker_emails[0]?.cc_emails
              ? truckerInfo?.trucker_emails[0]?.cc_emails
              : [],
          },
        ];

        Object.assign(truckerInfo?.trucker_emails, email);

        let aux = truckerInfo?.trucker_emails[0]?.email;

        aux = value;
        curTrucker["trucker_emails"][0]["email"] = aux;
        updateTrucker(curTrucker);
      }
    } else {
      let curTrucker = truckerInfo;
      let aux = [...truckerInfo?.trucker_emails[0]?.cc_emails];
      aux[i] = value;

      curTrucker["trucker_emails"][0]["cc_emails"] = aux;
      updateTrucker(curTrucker);
    }
  };

  const handleDeleteEmail = (i) => {
    let curTrucker = truckerInfo;
    let aux = [...truckerInfo?.trucker_emails[0]?.cc_emails];
    aux.splice(i, 1);
    curTrucker["trucker_emails"][0]["cc_emails"] = aux;
    updateTrucker(curTrucker);
    if (aux.length < 10) {
      setNumberOfEmailsError(false);
    }
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    var curTrucker = truckerInfo;
    curTrucker.name = e?.target?.value;
    setNameErrorMsg("");
    updateTrucker(curTrucker);

    if (e.target.value.length <= 0) {
      setNameErrorMsg("Cannot be empty");
    }
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    var curTrucker = truckerInfo;

    curTrucker.phone = e.target.value;
    setPhoneErrorMsg("");
    updateTrucker(curTrucker);

    if (e.target.value.length <= 0) {
      setPhoneErrorMsg("Cannot be empty");
    }
  };

  return (
    <>
      <div className="individual-header" onClick={openTab}>
        <h3 className="name">
          {truckerInfo.name ? truckerInfo.name : "New Trucker"} <br />
        </h3>
        <div className="arrow">{opened ? <FaAngleUp /> : <FaAngleDown />}</div>
      </div>
      {opened ? (
        <div className="trucker-form">
          <div className="input-section name_phone">
            <div className="input-container name">
              <label className="input-label">Name</label>

              <div className="input">
                <div className="input-icon">
                  <FaUser />
                </div>
                <input
                  type="text"
                  value={truckerInfo?.name}
                  onChange={handleNameChange}
                />
              </div>
              <p className="error-msg">{nameErrorMsg}</p>
            </div>
          </div>
          <div className="input-section render-emails">
            <label className="input-label">Main Email Address</label>
            <div className="input-container existing-email" key={0}>
              <div className="bottom">
                <div className="input">
                  <div className="input-icon">
                    <FaEnvelope />
                  </div>
                  <input
                    type="text"
                    value={truckerInfo?.trucker_emails[0]?.email}
                    onChange={(e) => {
                      e.preventDefault();
                      handleUpdateEmail(-1, e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <label className="input-label">CC Email Addresses</label>
            {truckerInfo?.trucker_emails[0]?.cc_emails?.map((email, i) => {
              return (
                <div className="input-container existing-email" key={i}>
                  <div className="bottom">
                    <div className="input">
                      <div className="input-icon">
                        <FaEnvelope />
                      </div>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => {
                          e.preventDefault();
                          handleUpdateEmail(i, e.target.value);
                        }}
                      />
                    </div>
                    <div className="buttons">
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteEmail(i);
                        }}
                      >
                        <p>—</p>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="input-section add-email">
            {numberOfEmailsError ? (
              <p
                style={{
                  color: "var(--red)",
                  marginBottom: "16px",
                  textDecoration: "underline",
                }}
              >
                Maximum 10 emails per trucker.
              </p>
            ) : (
              <></>
            )}
            <button
              className="new-email"
              onClick={addEmailInput}
              style={
                numberOfEmailsError
                  ? {
                      pointerEvents: "none",
                      opacity: ".5",
                    }
                  : {}
              }
            >
              Add New Email
            </button>
          </div>

          <button className="delete-trucker" onClick={handleDelete}>
            <p>Delete</p>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
