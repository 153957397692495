import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export const formatDate = (date) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "-" + mm + "-" + yyyy;
};

export const URL = process.env.REACT_APP_API_URL;

let curToken = "";
export const token = () => {
  if (!curToken || curToken.length < 1) {
    curToken = cookies.get("token_user");
  }
  return curToken;
};
export const resetToken = () => {
  curToken = "";
};

let curRole = "";
export const role = () => {
  if (!curRole || curRole.length < 1) {
    curRole = cookies.get("role_user");
  }
  return curRole;
};
export const resetRole = () => {
  curRole = "";
};

export const today = moment().toDate();
export const last_week = moment().subtract(1, "week").endOf("week").toDate();
export const last_month = moment().subtract(1, "month").endOf("month").toDate();
export const last_30_days = moment().subtract(30, "days").toDate();
export const last_3_months = moment().subtract(3, "months").toDate();
export const year_to_date = moment().startOf("year").toDate();

export const LoadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        width: "10px",
        height: "10px",
        margin: "auto",
        display: "block",
        shapeRendering: "auto",
      }}
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        strokeWidth="7"
        r="47"
        strokeDasharray="221.48228207808043 75.82742735936014"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

const formatMomentDate = (date) => {
  function getMonthNumber(monthName) {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    for (var i = 0; i < month.length; i++) {
      if (monthName === month[i]) {
        return i + 1;
      }
    }
    return -1;
  }

  let day = "";
  let month = "";
  let year = "";
  for (let i = 4; i < 7; i++) {
    month += date.toString()[i];
  }
  for (let i = 8; i < 10; i++) {
    day += date.toString()[i];
  }
  for (let i = 11; i < 15; i++) {
    year += date.toString()[i];
  }

  let form = `${day}-${getMonthNumber(month)}-${year}`;
  return form;
};
/**
 * Provide a date and whit will create a range between the date given and today
 * in this format (day-month-year,currentDay-currentMonth-currentYear)
 */
export const getDateRangeFilter = (range) => {
  let val = "";

  if (range === "week") {
    val = `${formatMomentDate(last_week)},${formatMomentDate(today)}`;
  } else if (range === "month") {
    val = `${formatMomentDate(last_month)},${formatMomentDate(today)}`;
  } else if (range === "30_days") {
    val = `${formatMomentDate(last_30_days)},${formatMomentDate(today)}`;
  } else if (range === "last_3_months") {
    val = `${formatMomentDate(last_3_months)},${formatMomentDate(today)}`;
  } else if (range === "year_to_date") {
    val = `${formatMomentDate(year_to_date)},${formatMomentDate(today)}`;
  }

  if (val === "all") {
    return "";
  } else {
    return val;
  }
};
// options={[
//   { label: "All Shipments", value: "" },
//   { label: "Released", value: "released" },
//   { label: "Awaiting Arrival", value: "submitted" },
//   { label: "Documents Required", value: "pending documents" },
//   { label: "Pending Release", value: "pending release" },
// ]}

export const shipmentStatusOptions = [
  { value: "all", label: "All Shipments" },
  { value: "released", label: "Released" },
  { value: "submitted", label: "Awaiting Arrival" },
  {
    value: "pending documents",
    label: "Documents Required",
  },
  { value: "PENDING RELEASE", label: "Awaiting Release" },
  { value: "CBP INTENSIVE EXAM", label: "CBP Intensive Exam" },
  { value: "CBP VACIS EXAM", label: "CBP Vacis Exam" },
  { value: "CBP DOCS REVIEW HOLD", label: "CBP Docs Review Hold" },
  { value: "ISF HOLD", label: "ISF Hold" },
  
];

export const homeShipmentStatusOptions = [
  { value: "all", label: "All Shipments" },
  {
    value: "pending documents",
    label: "Documents Required",
  },
  { value: "PENDING RELEASE", label: "Awaiting Release" },
  { value: "CBP INTENSIVE EXAM", label: "CBP Intensive Exam" },
  { value: "CBP VACIS EXAM", label: "CBP Vacis Exam" },
  { value: "CBP DOCS REVIEW HOLD", label: "CBP Docs Review Hold" },
  { value: "ISF HOLD", label: "ISF Hold" },
];

export const shipmentDateOptions = [
  { value: "all", label: "All Dates" },
  { value: "week", label: "Last Week" },
  { value: "30_days", label: "Last 30 Days" },
  {
    value: "last_3_months",
    label: "Last 3 Months",
  },
  { value: "year_to_date", label: "Year to Date" },
];

export const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "32px",
    minHeight: "none",
    width: "auto",
    minWidth: "129px",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const statusSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "32px",
    minHeight: "none",
    width: "auto",
    minWidth: "180px",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const formSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "33px",
    minHeight: "none",
    width: "auto",
    minWidth: "129px",
    border: "1px solid var(--border-color)",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "14px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
    opacity: ".5",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const SuccessMessage = ({ style }) => {
  return (
    <>
      <div
        id="success-msg"
        style={{
          ...style,
          width: "fit-content",
        }}
      >
        <p>✓ Info succesfuly saved</p>
      </div>
    </>
  );
};

export const UnsavedChanges = () => {
  return (
    <div className="not-saved" style={{ display: "block!important" }}>
      Several Changes Not Saved
    </div>
  );
};

export const LoadingTable = () => {
  return <div className="loading-table"></div>;
};

export const validateEmail = (email) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true;
  }
  return false;
};

export const objToUrl = (url, obj) => {
  const searchParams = new URLSearchParams();

  for (const key of Object.keys(obj)) {
    if (obj[key].length > 0 || typeof obj[key] === "number") {
      searchParams.append(key, obj[key]);
    }
  }

  let aux = `${url}?${searchParams}`;

  return aux;
};

export const fixedFormat = (dateStr) => {
  let date = new Date(dateStr);

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
};
