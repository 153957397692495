import { Title } from "./Title";
export const Error = () => {
    return (
        <>
        <Title title="Error :(" />
            <div id="error" className="page-content">
                <h1>ERROR <br /> THIS PAGE DOES NOT EXIST :(</h1>
                <h1>😢</h1>
            </div>
        </>
    );
}