import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";

import home_logo from "../assets/nav-bar-icons/home-logo.svg";
import shipments_logo from "../assets/nav-bar-icons/shipments-logo.svg";
import billing_logo from "../assets/nav-bar-icons/billing-logo.svg";
import reports_logo from "../assets/nav-bar-icons/reports-logo.svg";
import settings_logo from "../assets/nav-bar-icons/settings-logo.svg";

import "../stylesheets/Sidebar.scss";
import { TbLogout } from "react-icons/tb";
import { HiUsers } from "react-icons/hi";
import Tooltip from "./common/Tooltip.jsx";
import { FaEllipsisH } from "react-icons/fa";

import { toast } from "react-toastify";
import { loadingAnimation } from "./scripts/functions";

const Sidebar = () => {
  const cookies = new Cookies();
  const token = cookies.get("token_user");
  const [role, setRole] = useState();

  const tooltipToggleRef = useRef(null);

  const navLinkStyles = ({ isActive }) => {
    return {
      opacity: isActive ? "1" : ".5",
    };
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!token || token == null) {
      navigate("/login");
    }
    setRole(cookies.get("role_user"));
  }, []);

  useEffect(() => {
    if (!token || token == null) {
      navigate("/login");
    }
  }, [cookies]);

  const [actTooltip, setActTooltip] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const URL = process.env.REACT_APP_API_URL + "/user";
  const logout = async () => {
    try {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/logout`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await resp.json();
      if (resp?.status == 200) {
        loadingAnimation(false);
        cookies.remove("token_user", { path: "/" });
        cookies.remove("name_user", { path: "/" });
        navigate("/login");
      } else {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      loadingAnimation(false);
      console.log(e);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handleResize = (e) => {
      if (e.target.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const navBarTooltipListener = (e, element) => {
      if (element.current && !element.current.contains(e.target)) {
        const tooltip = element?.current.querySelector(
          ".custom-tooltip-container"
        );
        if (tooltip.classList.contains("active-tooltip")) {
          setActTooltip(false);
        }
      }
    };

    document.addEventListener("mousedown", (e) => {
      navBarTooltipListener(e, tooltipToggleRef);
    });
    return () => {
      document.removeEventListener("mousedown", (e) => {
        navBarTooltipListener(e, tooltipToggleRef);
      });
    };
  }, []);

  return (
    <aside className="navbar">
      <img
        src={require("../assets/atlantic-main-logo.png")}
        alt="main-logo"
        id="nav-bar_main-img"
      />
      <ul>
        <li className="navbar-link">
          <NavLink to="/" style={navLinkStyles}>
            <img
              src={home_logo}
              alt="home"
              id="home-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>
        <li className="navbar-link">
          <NavLink to="/shipments" style={navLinkStyles}>
            <img
              src={shipments_logo}
              alt="shipments"
              id="shipments-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>
        <li className="navbar-link">
          <NavLink to="/billing" style={navLinkStyles}>
            <img
              src={billing_logo}
              alt="billing"
              id="billing-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>
        {role != 5 && (
          <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
            <NavLink to="/users" style={navLinkStyles}>
              <HiUsers />
            </NavLink>
          </li>
        )}
        <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
          <NavLink to="/reports" style={navLinkStyles}>
            <img
              src={reports_logo}
              alt="reports"
              id="reports-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>
        <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
          <NavLink to="/settings" style={navLinkStyles}>
            <img
              src={settings_logo}
              alt="settings"
              id="settings-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>
        {isMobile && (
          <li
            ref={tooltipToggleRef}
            className="navbar-link p-relative"
            onClick={() => {
              if (actTooltip === false) {
                setActTooltip(true);
              } else if (actTooltip === true) {
                setActTooltip(false);
              }
            }}
          >
            <a className="logout-btn">
              <FaEllipsisH />
            </a>

            <Tooltip
              myComponents={[
                role != 5 ? (
                  <NavLink to="/users" style={navLinkStyles}>
                    <HiUsers />
                  </NavLink>
                ) : (
                  ""
                ),
                <NavLink to="/reports" style={navLinkStyles}>
                  <img
                    src={reports_logo}
                    alt="reports"
                    id="reports-logo"
                    className="nav-logo"
                  />
                </NavLink>,
                <NavLink to="/settings" style={navLinkStyles}>
                  <img
                    src={settings_logo}
                    alt="settings"
                    id="settings-logo"
                    className="nav-logo"
                  />
                </NavLink>,
              ]}
              activeTooltip={actTooltip}
            />
          </li>
        )}
        <li className="navbar-link">
          <a className="logout-btn" onClick={logout}>
            <TbLogout />
          </a>
        </li>
      </ul>
    </aside>
  );
};
export default Sidebar;
